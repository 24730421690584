<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{ $t("message.company") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.mfo.show">
                {{ columns.mfo.title }}
              </th>

              <th v-if="columns.okonx.show">
                {{ columns.okonx.title }}
              </th>

              <th v-if="columns.oked.show">
                {{ columns.oked.title }}
              </th>

               <th v-if="columns.rkp_nds.show">
                {{ columns.rkp_nds.title }}
              </th>

              <th v-if="columns.work_phone.show">
                {{ columns.work_phone.title }}
              </th>

              <th v-if="columns.country_id.show">
                {{ columns.country_id.title }}
              </th>

              <th v-if="columns.city_id.show">
                {{ columns.city_id.title }}
              </th>

              <th v-if="columns.work_place.show">
                {{ columns.work_place.title }}
              </th>

              <th v-if="columns.legal_address.show">
                {{ columns.legal_address.title }}
              </th>

              <th v-if="columns.landmark.show">
                {{ columns.landmark.title }}
              </th>

              <th v-if="columns.residence_place.show">
                {{ columns.residence_place.title }}
              </th>

              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.name.show">
                <crm-input
                  :placeholder="columns.name.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>

              <th v-if="columns.mfo.show">
                <crm-input
                  :placeholder="columns.mfo.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.mfo"
                ></crm-input>
              </th>

              <th v-if="columns.okonx.show">
                <crm-input
                  :placeholder="columns.okonx.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.okonx"
                ></crm-input>
              </th>

              <th v-if="columns.oked.show">
                <crm-input
                  :placeholder="columns.oked.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.oked"
                ></crm-input>
              </th>

              <th v-if="columns.rkp_nds.show">
                <crm-input
                  :placeholder="columns.rkp_nds.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.rkp_nds"
                ></crm-input>
              </th>

              <th v-if="columns.work_phone.show">
                <crm-input
                  :placeholder="columns.work_phone.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.work_phone"
                ></crm-input>
              </th>

              <th v-if="columns.country_id.show">
                <select-country
                  :placeholder="columns.country_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.country_id"
                ></select-country>
              </th>

              <th v-if="columns.city_id.show">
                <select-city
                  :placeholder="columns.city_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.city_id"
                ></select-city>
              </th>

              <th v-if="columns.work_place.show">
                <crm-input
                  :placeholder="columns.work_place.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.work_place"
                ></crm-input>
              </th>
            
              <th v-if="columns.legal_address.show">
                <crm-input
                  :placeholder="columns.legal_address.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.legal_address"
                ></crm-input>
              </th>
            
              <th v-if="columns.landmark.show">
                <crm-input
                  :placeholder="columns.landmark.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.landmark"
                ></crm-input>
              </th>
            

              <th v-if="columns.residence_place.show">
                <crm-input
                  :placeholder="columns.residence_place.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.residence_place"
                ></crm-input>
              </th>
            
              <th v-if="columns.comment.show">
                <crm-input
                  :placeholder="columns.comment.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="company in list"
              :key="company.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">
                {{ company.id }}
              </td>

              <td v-if="columns.name.show">
                {{ company.name }}
              </td>

              <td v-if="columns.mfo.show">
                {{ company.mfo }}
              </td>

              <td v-if="columns.okonx.show">
                {{ company.okonx }}
              </td>

              <td v-if="columns.oked.show">
                {{ company.oked }}
              </td>

              <td v-if="columns.rkp_nds.show">
                {{ company.rkp_nds }}
              </td>

              <td v-if="columns.work_phone.show">
                {{ company.work_phone }}
              </td>

              <td v-if="columns.country_id.show">
                {{ company.country ? company.country.name : "" }}
              </td>

              <td v-if="columns.city_id.show">
                {{ company.city ? company.city.name : "" }}
              </td>


              <td v-if="columns.work_place.show">
                {{ company.work_place }}
              </td>

              <td v-if="columns.legal_address.show">
                {{ company.legal_address }}
              </td>

              <td v-if="columns.landmark.show">
                {{ company.landmark }}
              </td>

              <td v-if="columns.residence_place.show">
                {{ company.residence_place }}
              </td>

              <td v-if="columns.comment.show">
                {{ company.comment }}
              </td>

              <td v-if="columns.created_at.show">
                {{ company.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ company.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="company"
                  :actions="actions"
                  :permissionShow="'companies.update'"
                  :permissionDestroy="'companies.delete'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          size="70%"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <div>
            <crm-create ref="drawerCreateChild" drawer="drawerCreate">
            </crm-create>
          </div>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "companyController",
  mixins: [list],
  components: {
    Pagination,
    CrmCreate,
    CrmUpdate,
    selectCountry,
    selectCity,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      list: "company/list",
      columns: "company/columns",
      pagination: "company/pagination",
      filter: "company/filter",
      sort: "company/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "company/index",
      setPagination: "company/setPagination",
      updateSort: "company/updateSort",
      updateFilter: "company/updateFilter",
      updateColumn: "company/updateColumn",
      updatePagination: "company/updatePagination",
      show: "company/show",
      empty: "company/empty",
      delete: "company/destroy",
      refreshData: "company/refreshData",
    }),
    
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('company/EMPTY_LIST');
    next()
  },
};
</script>

